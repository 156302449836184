/*@tailwind base;*/
/*@tailwind components;*/
/*@tailwind utilities;*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.875rem
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

:root {
  --header-height: 75px;
}

.flex-auto-0 {
  flex: 0 0 auto !important;
}

.inset-0 {
  inset: 0;
}

.inset-1 {
  inset: 1em;
}

.order-table.p-datatable .p-datatable-tbody > tr.needs-order {
   background-color: rgba(244,143,177,0.1);
 }

.order-table.p-datatable .p-datatable-tbody > tr.ok {
   background-color: rgba(129,199,132,0.1);
 }

.order-table.p-datatable .p-datatable-tbody > tr.needs-planning {
   background-color: rgba(255,224,130,0.1);
 }

.task-table.p-datatable .p-datatable-thead > tr > th,
.task-table.p-datatable .p-datatable-tbody > tr > td {
   padding: 0.125rem;
 }

.task-table.p-datatable .p-datatable-thead > tr > th {
   font-size: 1.25rem;
 }

.h-75vh {
  height: 75vh !important;
}

.h-50vh {
  height: 50vh !important;
}

@media screen and (min-width: 576px) {
  .sm\:h-75vh {
    height: 75vh !important;
  }

  .md\:h-50vh {
    height: 50vh !important;
  }
}

.hover\:overflow-visible:hover {
  overflow: visible !important;
}
